import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ion-text-center ion-padding"
}
const _hoisted_2 = { style: {"font-size":"x-small"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_item_option = _resolveComponent("ion-item-option")!
  const _component_ion_item_options = _resolveComponent("ion-item-options")!
  const _component_ion_item_sliding = _resolveComponent("ion-item-sliding")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, {
                    "default-href": "/tabs/home/settings",
                    text: _ctx.$t('Back')
                  }, null, 8, ["text"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "primary" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.router.push('/tabs/budget/item/' + _ctx.groupId + '/' + _ctx.useWishList.toString())), ["prevent"]))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "end",
                        icon: _ctx.addCircle
                      }, null, 8, ["icon"]),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('Add')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.categoryTitle), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, { class: "ion-text-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_buttons, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        fill: _ctx.useWishList ? 'outline' : 'solid',
                        onClick: _cache[2] || (_cache[2] = () => _ctx.useWishList = 0)
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            slot: "start",
                            icon: _ctx.useWishList ? _ctx.ellipseOutline : _ctx.checkmarkCircleOutline
                          }, null, 8, ["icon"]),
                          _createTextVNode(_toDisplayString(_ctx.$t('Essential')), 1)
                        ]),
                        _: 1
                      }, 8, ["fill"]),
                      _createVNode(_component_ion_button, {
                        fill: _ctx.useWishList ? 'solid' : 'outline',
                        onClick: _cache[3] || (_cache[3] = () => _ctx.useWishList = 1)
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            slot: "start",
                            icon: _ctx.useWishList ? _ctx.checkmarkCircleOutline : _ctx.ellipseOutline
                          }, null, 8, ["icon"]),
                          _createTextVNode(_toDisplayString(_ctx.$t('Wish List')), 1)
                        ]),
                        _: 1
                      }, 8, ["fill"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.categoryTitle), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_note, {
                    style: {"font-weight":"bold"},
                    slot: "end"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$curr(_ctx.useWishList ? _ctx.store.getters.wishListTotals[_ctx.groupId] : _ctx.store.getters.budgetGroupValues[_ctx.groupId], _ctx.store.state.userData && _ctx.store.state.userData.country && _ctx.store.state.userData.country.code)), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.itemList, (val, idx) => {
                return (_openBlock(), _createBlock(_component_ion_item_sliding, {
                  key: val.id
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, {
                      onClick: ($event: any) => (_ctx.itemClicked(idx))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(val.budget_name), 1)
                          ]),
                          _: 2
                        }, 1024),
                        (_ctx.updateIdx == idx)
                          ? (_openBlock(), _createBlock(_component_ion_spinner, {
                              key: 0,
                              slot: "start"
                            }))
                          : (_openBlock(), _createBlock(_component_ion_icon, {
                              key: 1,
                              icon: val.payed ? _ctx.checkmarkCircle : _ctx.ellipseOutline,
                              color: val.payed ? 'primary' : 'text',
                              slot: "start",
                              onClick: _withModifiers(($event: any) => (_ctx.iconClicked(idx)), ["stop"])
                            }, null, 8, ["icon", "color", "onClick"])),
                        _createVNode(_component_ion_note, { slot: "end" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$curr(val.amount * val.quantity - val.paid - val.deposit, _ctx.store.state.userData && _ctx.store.state.userData.country && _ctx.store.state.userData.country.code)), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_ion_item_options, { side: "end" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item_option, {
                          color: "primary-contrast",
                          onClick: ($event: any) => (_ctx.deleteClicked(val.id))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              icon: _ctx.trashOutline,
                              color: "danger",
                              style: {"font-size":"30px"}
                            }, null, 8, ["icon"])
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128)),
              (_ctx.itemList.length < 1)
                ? (_openBlock(), _createBlock("div", _hoisted_1, [
                    _createVNode("p", null, _toDisplayString(_ctx.$t('There are no items in this category.')), 1),
                    _createVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('Tap "Add" above to add a new item to this category.')), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}