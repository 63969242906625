
import { 
  IonPage, 
  IonHeader, 
  IonToolbar, 
  IonTitle, 
  IonContent,
  IonButtons,
  IonButton,
  IonBackButton,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonSpinner,
  IonNote,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,

 } from '@ionic/vue';

import { 
  checkmark, 
  addCircle,
  ellipseOutline,
  checkmarkCircleOutline,
  checkmarkCircle,
  trashOutline,
}  from 'ionicons/icons';

import { defineComponent, ref, computed } from '@vue/runtime-core';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from '@/store';

export default  defineComponent({
  name: 'WeddingInfo',
  components: { 
    IonPage, 
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonContent,
    IonButtons,
    IonButton,
    IonBackButton,
    IonIcon,
    IonList,
    IonItem,
    IonLabel,
    //IonCheckbox,
    IonSpinner,
    IonNote,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    //IonSelectOption,


    //MInput,
    //MSelect,
    //MPassword,
  },
  setup(){

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const updateIdx = ref(-1);
    const useWishList = ref(0);

    const groupId = parseInt(route.params.budgetCategoryId as string);
    const itemList = computed(() => {
      return store.state.budgets.filter(el => el.budget_group.id == groupId && el.importance == useWishList.value);
    });
    
    const categoryTitle = computed(() => {
      return store.state.budgetGroups.find(el => el.id == groupId)!.name;
    });

    async function iconClicked(idx: number){
      const item = itemList.value[idx];
      const newItem = {
        id: item.id,
        payed: item.payed ? 0 : 1,
      }
      updateIdx.value = idx;
      await store.dispatch('saveBudgetItem', newItem);
      updateIdx.value = -1;
    }

    function itemClicked(idx: number){
      const item = itemList.value[idx];
      router.push('/tabs/budget/item/' + item.id);
    }

    function deleteClicked(id: number){
      store.dispatch('deleteBudgetItem', id);
    }

    return {
      itemList,
      categoryTitle,
      groupId,
      checkmark,
      addCircle,
      router,
      iconClicked,
      itemClicked,
      deleteClicked,
      updateIdx,
      ellipseOutline,
      checkmarkCircleOutline,
      checkmarkCircle,
      trashOutline,
      useWishList,
      store,
    }
  }
});
